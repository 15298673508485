import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from "react-i18next";
import i18n from '../i18n';
import { useNavigate, useLocation, useNavigationType, NavigationType } from "react-router-dom";
import ReactLoading from 'react-loading';
import { AiOutlineMinusCircle } from "react-icons/ai";
import { MdOutlineFilterAlt } from "react-icons/md";
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import { writeFileXLSX, utils } from 'xlsx';
//context
import UserContext from '../context/UserContext';
//api
import aws from '../api/aws';
//components
import { NumberKgCO2e } from '../components/KgCO2e';
import { ButtonFull, ButtonEmpty } from '../components/Buttons';
import { ButtonOption, RadioOption } from '../components/ToolBarOption';
import { OrderArrows } from '../components/OrderArrows';
//util
import { colors, colorValuesRecipes } from '../util/values';
import { commonStyles } from '../util/styles';
import { sortBy, getColor, formatNumber } from '../util/climatecook';

const RecipesScreen = () => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const navigationType = useNavigationType();
  const [title, setTitle] = useState("");
  const [recipes, setRecipes] = useState([]);
  const [visibleRecipes, setVisibleRecipes] = useState([]);
  const [search, setSearch] = useState("");
  const [orderType, setOrderType] = useState("name");
  const [isOrderAsc, setIsOrderAsc] = useState(true);
  const [categories, setCategories] = useState([]);
  const [usedCategories, setUsedCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [allSelectedCategories, setAllSelectedCategories] = useState(1);
  const [hasNullCategories, setHasNullCategories] = useState(true);
  const [isNullCategories, setIsNullCategories] = useState(true);
  const [menus, setMenus] = useState([]);
  const [selectedMenus, setSelectedMenus] = useState({});
  const [allSelectedMenus, setAllSelectedMenus] = useState(1);
  const [hover, setHover] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalCategories, setShowModalCategories] = useState(false);
  const [showModalMenus, setShowModalMenus] = useState(false);
  const [deleteId, setDeleteId] = useState(-1);
  const [showPhotos, setShowPhotos] = useState(false);
  const [showMenus, setShowMenus] = useState(false);
  const firstUpdate = useRef(true);
  const [refreshPls, setRefreshPls] = useState(0);

  const handleMouseEnter = (which) => {
    setHover(which);
  };

  const handleMouseLeave = () => {
    setHover("");
  };

  useEffect(() => {
    if ((location.state && location.state.isFromNav)) {
      localStorage.removeItem("recipeValues");
    }
    let nav = JSON.parse(localStorage.getItem("nav"));
    setTitle(nav != null ? nav[i18n.language].recipes : t('routeMyRecipes'));
    setShowPhotos(localStorage.getItem("showPhotos") === 'true');
    setShowMenus(localStorage.getItem("showMenus") === 'true');

    let recipeValues = JSON.parse(localStorage.getItem("recipeValues"));
    setIsNullCategories(recipeValues ? recipeValues.isNullCategories : true);
    setSearch(recipeValues ? recipeValues.search : "");
    setIsOrderAsc(recipeValues ? recipeValues.isOrderAsc : true);
    setOrderType(recipeValues ? recipeValues.orderType : "name");
    //getMenus();
    getCategories();
  }, []);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    getRecipes(isOrderAsc);
  }, [categories]);

  useEffect(() => {
    allCheckboxState();
  }, [selectedCategories, isNullCategories]);

  useEffect(() => {
    allCheckboxStateMenus();
  }, [selectedMenus]);

  useEffect(() => {
    setRefreshPls(previous => previous + 1);
  }, [isOrderAsc, orderType]);

  useEffect(() => {
    showRecipes();
  }, [refreshPls]);

  const onExport = async () => {
    let rows = [];
    for (let r of recipes) {
      let menu = r.menus.replaceAll(';;', ', ');
      rows.push({
        [t('lblName')]: r[i18n.language === 'fr' ? 'name_fra' : 'name_eng'],
        ['KgCo2e/Portion']: (r.co2 / r.portions).toFixed(3),
        [t('lblCategory')]: r.categoryName,
        [t('lblMenu')]: menu,
        [t('lblGreen')]: r.thresholdLow.toFixed(3),
        [t('lblYellow')]: r.thresholdHigh.toFixed(3)
      });
    }
    const workbook = utils.book_new();

    const worksheet = utils.json_to_sheet(rows);
    utils.book_append_sheet(workbook, worksheet, title);

    let nav = JSON.parse(localStorage.getItem("nav"));
    const response = await aws.get('/stats', { params: { type: 'recipes', userId: user[0].user_id, lang: i18n.language } });
    let rows2 = [];
    let baseIngs = [];
    for (let r of response.data) {
      if (r.partner !== null && !baseIngs.some(e => e[t('lblName')] === r.name)) {
        baseIngs.push({
          [t('lblName')]: r.name,
          ['KgCo2e/Portion']: '',
          [t('lblCategory')]: '',
          [t('lblIsRecipe')]: 'Source',
          [t('lblYieldKg')]: r.yield_kg2,
          [t('lblYieldL')]: r.yield_l2,
          [t('lblYieldUnit')]: r.yield_unit2,
          [t('lblPortions')]: r.portions2,
          [t('lblIngName')]: '',
          [t('lblQuantity')]: '',
          [t('lblUnit')]: '',
          [t('lblIngSourceName')]: r.source_name,
          [t('lblIngSource')]: r.partner,
        });
      }

      let isRecipe;
      if (r.owner === null && r.is_recipe === 1) {
        isRecipe = t('lblCCRecipe');
      } else if (r.owner === null && r.is_recipe === 0) {
        isRecipe = t('lblCCSubRecipe');
      } else if (r.owner === null && r.is_recipe === 2) {
        isRecipe = t('lblCCIngredient');
      } else if (r.is_recipe === 1) {
        isRecipe = nav[i18n.language].recipes !== null ? nav[i18n.language].recipes : t('routeMyRecipes');
      } else if (r.is_recipe === 0) {
        isRecipe = nav[i18n.language].subRecipes !== null ? nav[i18n.language].subRecipes : t('routeMySubRecipes');
      } else {
        isRecipe = nav[i18n.language].ingredients !== null ? nav[i18n.language].ingredients : t('routeMyIngredients');
      }
      rows2.push({
        [t('lblName')]: r.main_name,
        ['KgCo2e/Portion']: r.is_recipe === 1 ? (r.co2 / r.portions).toFixed(3) : '',
        [t('lblCategory')]: r.categoryName,
        [t('lblIsRecipe')]: isRecipe,
        [t('lblYieldKg')]: r.yield_kg,
        [t('lblYieldL')]: r.yield_l,
        [t('lblYieldUnit')]: r.yield_unit,
        [t('lblPortions')]: r.portions,
        [t('lblIngName')]: r.name,
        [t('lblQuantity')]: r.quantity,
        [t('lblUnit')]: r.unit,
        [t('lblIngSourceName')]: '',
        [t('lblIngSource')]: '',
      });
    }
    baseIngs = baseIngs.sort(sortBy(t('lblName'), true));
    rows2.push(...baseIngs);

    const worksheet2 = utils.json_to_sheet(rows2);
    utils.book_append_sheet(workbook, worksheet2, t("lblHierarchyAndSource"));

    const aoa = [
      [null, null],
      [null, "Dans l'onglet 'Hiérarchie et source', sous les colonnes 'Rend. Kg., Rend. L, Rend. Unit, Portions', il y a parfois '-1'. \nCela indique que le rendement correspondant est le même que celui de l'ingrédient du niveau en dessous et est automatiquement lié à celui-ci."],
    ];
    const worksheet3 = utils.aoa_to_sheet(aoa);
    utils.book_append_sheet(workbook, worksheet3, "Explication");

    writeFileXLSX(workbook, "Recipes.xlsx", { bookType: "xlsx", compression: true });
  };

  const getMenus = async () => {
    const response = await aws.get('/menus', { params: { owner: user[0].user_id, which: 'used' } });
    if (response.data) {
      let newSelectedMenus = { null: true };
      let newMenus = ['null'];
      let recipeValues = JSON.parse(localStorage.getItem("recipeValues"));
      for (let m of response.data) {
        newSelectedMenus[m.name] = recipeValues && recipeValues.selectedMenus[m.name] !== undefined ? recipeValues.selectedMenus[m.name] : true;
        newMenus.push(m.name);
      }

      setSelectedMenus(newSelectedMenus);
      setMenus(newMenus);
    }
  };

  const getCategories = async () => {
    const response = await aws.get('/categories', { params: { owner: user[0].user_id } });
    if (response.data) {
      let newSelectedCategories = {};
      let newCategories = [];
      let recipeValues = JSON.parse(localStorage.getItem("recipeValues"));
      for (let cat of response.data) {
        newSelectedCategories[cat.id] = recipeValues && recipeValues.selectedCategories[cat.id] !== undefined ? recipeValues.selectedCategories[cat.id] : true;
        newCategories.push({
          id: cat.id,
          name_eng: cat.name_eng,
          name_fr: cat.name_fr,
          thresholdHigh: cat.threshold_high === null ? colorValuesRecipes.ingredientHigh : cat.threshold_high,
          thresholdLow: cat.threshold_low === null ? colorValuesRecipes.ingredientLow : cat.threshold_low
        });
      }
      setSelectedCategories(newSelectedCategories);
      setCategories(newCategories);
    }
  };

  const getRecipes = async (asc) => {
    setShowModalCategories(false);

    let categoryIn = [];
    for (let key in selectedCategories) {
      if (selectedCategories[key]) {
        categoryIn.push(key);
      }
    }
    if (categoryIn.length === 0 && !isNullCategories) {
      setVisibleRecipes([]);
      return;
    }

    setIsReady(false);

    try {
      let response = await aws.get('/recipes', { params: { term: '', owner: user[0].user_id, isRecipe: 1, asc, lang: i18n.language, selectFrom: 'ingredients' } });
      if (response.data) {
        let hasNullCat = false;
        let newRecipes = [];
        let newUsedCategories = [];
        for (let i = 0; i < response.data.length; i++) {
          let categoryName = null;
          let thresholdHigh = colorValuesRecipes.ingredientHigh;
          let thresholdLow = colorValuesRecipes.ingredientLow;
          for (let j = 0; j < categories.length; j++) {
            if (response.data[i].food_group == categories[j].id) {
              if (!newUsedCategories.includes(categories[j])) {
                newUsedCategories.push(categories[j]);
              }
              categoryName = i18n.language === "fr" ? categories[j].name_fr : categories[j].name_eng;
              thresholdHigh = categories[j].thresholdHigh;
              thresholdLow = categories[j].thresholdLow;
            }
          }
          let menusArray = [];
          if (response.data[i].menus !== '') {
            let recipeMenus = response.data[i].menus.split(";;");
            menusArray = recipeMenus;
          }
          response.data[i].menusArray = menusArray;

          newRecipes.push({ ...response.data[i], categoryName: categoryName, thresholdHigh, thresholdLow });
          if (response.data[i].food_group === null) {
            hasNullCat = true;
          }
        }

        setHasNullCategories(hasNullCat);
        setRecipes(newRecipes);
        if (location.state && location.state.isFromNav) {
          setVisibleRecipes(newRecipes);
        }
        setUsedCategories(newUsedCategories);
        setRefreshPls(previous => previous + 1);
        setIsReady(true);
      }
    } catch (err) {
      console.log(err); //TODO handle error
      setIsReady(true);
    }
  };

  const onRecipeClick = (ingredient) => {
    navigate("recipe", { state: { ingredient: [ingredient], menus: menus.filter(item => item !== 'null') } });
  };

  const onEnterPressed = (key) => {
    if (key === 'Enter') {
      showRecipes();
    }
  };

  const toggleOrder = (type) => {
    setIsOrderAsc(!isOrderAsc);
    setOrderType(type);
  };

  const showRecipes = () => {
    if (Object.keys(selectedCategories).length !== 0 && Object.keys(selectedMenus).length !== 0) {
      let recipeValues = {
        search: search,
        orderType: orderType,
        isOrderAsc: isOrderAsc,
        selectedCategories: selectedCategories,
        isNullCategories: isNullCategories,
        selectedMenus: selectedMenus
      };
      localStorage.setItem("recipeValues", JSON.stringify(recipeValues));
    }

    let categoryIn = [];
    let menuIn = [];
    for (let key in selectedCategories) {
      if (selectedCategories[key]) {
        categoryIn.push(key.toString());
      }
    }
    if (isNullCategories) {
      categoryIn.push(null);
    }
    for (let key in selectedMenus) {
      if (selectedMenus[key]) {
        menuIn.push(key);
      }
    }
    let newRecipes = [];
    for (let i = 0; i < recipes.length; i++) {
      let name = i18n.language === 'fr' ? recipes[i].name_fra : recipes[i].name_eng;
      name = name.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase();
      let searchInput = search.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase();
      if (categoryIn.includes(recipes[i].food_group) && (menuIn.some(o => recipes[i].menus.includes(o)) || (recipes[i].menus === "" && selectedMenus.null)) && name.includes(searchInput)) {
        let menuStr = "";
        let max = 0;
        for (let j = recipes[i].menusArray.length - 1; j >= 0 && max < 2; j--) {
          if (selectedMenus[recipes[i].menusArray[j]]) {
            menuStr += recipes[i].menusArray[j];
            if (max === 0 && recipes[i].menusArray.length > 1) menuStr += ', ';
            max++;
          }
        }
        if ((max === 1 && recipes[i].menusArray.length > 1) || (max === 2 && recipes[i].menusArray.length > 2)) menuStr = menuStr.substring(0, menuStr.length - 2) + '...';
        newRecipes.push({ ...recipes[i], menuStr });
      }
    }

    newRecipes = setOrder(isOrderAsc, newRecipes);
    setVisibleRecipes(newRecipes);
    setShowModalCategories(false);
    setShowModalMenus(false);
  };

  const setOrder = (order, recipeList) => {
    let newRecipes;
    if (orderType === 'name') {
      newRecipes = recipeList.sort(sortBy(i18n.language === 'fr' ? 'name_fra' : 'name_eng', order, true));
    } else {
      newRecipes = recipeList.sort(sortBy('co2', order, true, Number));
    }
    return newRecipes;
  };

  const onDelete = (event, id) => {
    event.stopPropagation();
    setDeleteId(id);
    setShowModal(true);
  };

  const onConfirmDelete = async () => {
    if (deleteId !== -1) {
      const response = await aws.delete('/ingredients/composition', { data: { id: deleteId, isArchived: 1 } });
      if (response.data) {
        getRecipes(isOrderAsc);
      }
    }
    setShowModal(false);
  };

  const allCheckboxState = () => {
    let trueCheckbox = null;
    let falseCheckbox = null;
    for (let key in selectedCategories) {
      if (selectedCategories[key] === true) {
        trueCheckbox = true;
      } else {
        falseCheckbox = true;
      }
    }
    if (isNullCategories === true) {
      trueCheckbox = true;
    } else {
      falseCheckbox = true;
    }

    if (trueCheckbox && !falseCheckbox) {
      setAllSelectedCategories(1);
    } else if (!trueCheckbox && falseCheckbox) {
      setAllSelectedCategories(0);
    } else {
      setAllSelectedCategories(2);
    }
  };

  const allCheckboxStateMenus = () => {
    let trueCheckbox = null;
    let falseCheckbox = null;
    for (let key in selectedMenus) {
      if (selectedMenus[key] === true) {
        trueCheckbox = true;
      } else {
        falseCheckbox = true;
      }
    }

    if (trueCheckbox && !falseCheckbox) {
      setAllSelectedMenus(1);
    } else if (!trueCheckbox && falseCheckbox) {
      setAllSelectedMenus(0);
    } else {
      setAllSelectedMenus(2);
    }
  };

  const selectAllCheckbox = (checked) => {
    let newSelectedCategories = selectedCategories;
    setIsNullCategories(checked);
    for (let key in newSelectedCategories) {
      newSelectedCategories[key] = checked;
    }
    setSelectedCategories(newSelectedCategories);
    setAllSelectedCategories(checked ? 1 : 0);
  };

  const selectAllCheckboxMenus = (checked) => {
    let newSelectedMenus = selectedMenus;
    for (let key in newSelectedMenus) {
      newSelectedMenus[key] = checked;
    }
    console.log(newSelectedMenus);
    setSelectedMenus(newSelectedMenus);
    setAllSelectedMenus(checked ? 1 : 0);
  };

  const onToggleMenu = (selected) => {
    setIsReady(false);
    setShowMenus(selected);
    if (!selected) {
      selectAllCheckboxMenus(true);
      showRecipes();
    }
    setIsReady(true);
    localStorage.setItem("showMenus", selected)
  };

  const onTogglePhotos = (selected) => {
    setShowPhotos(selected);
    localStorage.setItem("showPhotos", selected)
  };

  const renderItemList = (itemList) => {
    return (
      <div>
        <table style={styles.table}>
          <tbody>
            <tr key="header">
              <th></th>
              <th></th>
              <th></th>
              <th style={{ ...styles.headerCell, cursor: 'pointer' }} onClick={() => toggleOrder("name")}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <OrderArrows id="name" name={t('lblName')} stateType={orderType} stateIsAsc={isOrderAsc} />
                </div>
              </th>
              <th style={{ ...styles.headerCell, cursor: 'pointer' }} onClick={() => toggleOrder("impact")}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <OrderArrows id="impact" name={t('lblImpact')} stateType={orderType} stateIsAsc={isOrderAsc} />
                </div>
              </th>
              <th style={{ ...styles.headerCell }}>
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: allSelectedCategories !== 1 ? colors.main : colors.text, fontWeight: allSelectedCategories !== 1 ? 600 : 400 }} onClick={() => setShowModalCategories(!showModalCategories)}>
                  {t('lblCategory')}
                  <MdOutlineFilterAlt size={16} color={allSelectedCategories !== 1 ? colors.main : colors.text} />
                </div>
                {showModalCategories ?
                  <div style={styles.modalDivCategories}>
                    <div key={"parent"} style={styles.modalCheckbox}>
                      <Checkbox sx={{ p: 0, ml: -1, mr: 1, my: 0.5, color: colors.main, '&.Mui-checked': { color: colors.main }, '&.MuiCheckbox-indeterminate': { color: colors.main } }}
                        size="small"
                        checked={allSelectedCategories === 1}
                        indeterminate={allSelectedCategories === 2}
                        onChange={(event) => selectAllCheckbox(event.target.checked)}
                      />
                      <div style={styles.modalCheckboxText}>{t('chkCategoriesAll')}</div>
                    </div>
                    {hasNullCategories ? <div key={"nullCategories"} style={styles.modalCheckbox}>
                      <Checkbox sx={{ p: 0, mr: 2, my: 0.5, color: colors.main, '&.Mui-checked': { color: colors.main } }}
                        size="small"
                        checked={isNullCategories}
                        onChange={(event) => setIsNullCategories(event.target.checked)} />

                      <div style={styles.modalCheckboxText}>{t('lblNullCategories')}</div>
                    </div> : null}
                    {usedCategories.map((item) => {
                      return (<div key={item.id + item.name_eng} style={styles.modalCheckbox}>
                        <Checkbox sx={{ p: 0, mr: 2, my: 0.5, color: colors.main, '&.Mui-checked': { color: colors.main } }}
                          size="small"
                          checked={selectedCategories[item.id]}
                          onChange={(event) => setSelectedCategories({ ...selectedCategories, [item.id]: event.target.checked })} />
                        <div style={styles.modalCheckboxText}>{i18n.language === "fr" ? item.name_fr : item.name_eng}</div>
                      </div>);
                    })}
                    <div style={{ marginBottom: 10 }} />
                    <ButtonFull text={t('btnOk')} onButtonClick={() => showRecipes()} />
                  </div> : null}
              </th>
              {showMenus ? <th style={{ ...styles.headerCell }}>
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: allSelectedMenus !== 1 ? colors.main : colors.text, fontWeight: allSelectedMenus !== 1 ? 600 : 400 }} onClick={() => setShowModalMenus(!showModalMenus)}>
                  {t('lblMenu')}
                  <MdOutlineFilterAlt size={16} color={allSelectedMenus !== 1 ? colors.main : colors.text} />
                </div>
                {showModalMenus ?
                  <div style={styles.modalDivCategories}>
                    <div key={"parentMenu"} style={styles.modalCheckbox}>
                      <Checkbox sx={{ p: 0, ml: -1, mr: 1, my: 0.5, color: colors.main, '&.Mui-checked': { color: colors.main }, '&.MuiCheckbox-indeterminate': { color: colors.main } }}
                        size="small"
                        checked={allSelectedMenus === 1}
                        indeterminate={allSelectedMenus === 2}
                        onChange={(event) => selectAllCheckboxMenus(event.target.checked)}
                      />
                      <div style={styles.modalCheckboxText}>{t('chkCategoriesAll')}</div>
                    </div>
                    {menus.map((item) => {
                      return (<div key={item} style={styles.modalCheckbox}>
                        <Checkbox sx={{ p: 0, mr: 2, my: 0.5, color: colors.main, '&.Mui-checked': { color: colors.main } }}
                          size="small"
                          checked={selectedMenus[item]}
                          onChange={(event) => setSelectedMenus({ ...selectedMenus, [item]: event.target.checked })} />
                        <div style={styles.modalCheckboxText}>{item === "null" ? t('lblNullCategories') : item}</div>
                      </div>);
                    })}
                    <div style={{ marginBottom: 10 }} />
                    <ButtonFull text={t('btnOk')} onButtonClick={() => showRecipes()} />
                  </div> : null}
              </th> : null}
              <th></th>
            </tr>
            {itemList.map((item) => {
              let itemCo2 = item.co2 / item.portions;
              let itemColor = getColor(itemCo2, 1, item.thresholdHigh, item.thresholdLow);

              return (
                <tr key={item.ingredient_id.toString()} style={{ backgroundColor: hover === item.ingredient_id ? colors.semiLightGrey : colors.lightGrey, cursor: 'pointer', transition: 'background-color 0.1s ease-out' }}
                  onMouseEnter={() => handleMouseEnter(item.ingredient_id)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => onRecipeClick(item)}>
                  <td style={{ ...styles.firstCell, backgroundColor: itemColor }}></td>
                  <td style={styles.gap}></td>
                  <td style={{ ...styles.cell, padding: '10px 0 10px 10px' }}>
                    {showPhotos && item.image_url ? <img style={{ display: 'block', width: 107, height: 70, borderRadius: 9, objectFit: 'cover' }} src={item.image_url} /> : null}
                  </td>
                  <td style={styles.cell}>{i18n.language === "fr" ? item.name_fra : item.name_eng}</td>
                  <td style={{ ...styles.cell, width: 178 }}><div> </div><NumberKgCO2e number={formatNumber(itemCo2)} size={16} weight={800} color={itemColor} portion /></td>
                  <td style={{ ...styles.cell, width: 200, color: colors.text }}>{item.categoryName}</td>
                  {showMenus ? <td style={{ ...styles.cell, width: 100, color: colors.text }}>{item.menuStr}</td> : null}
                  <td style={styles.lastCell} onClick={(event) => onDelete(event, item.ingredient_id)}>
                    <AiOutlineMinusCircle size={24} color={colors.delete} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {visibleRecipes.length > 0 ? null : recipes.length > 0 ? null :
          <div style={{ marginTop: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '70%', fontSize: 25, fontWeight: 'bold', color: colors.main, textAlign: 'center' }}>{t("textEmptyRecipes")}</div>
          </div>
        }
      </div >

    );
  };

  return (
    <div style={commonStyles.container}>
      {showModal ? <div style={styles.modalWindow}>
        <div style={styles.modalDiv}>
          <div style={styles.modalText}>{t('lblDeleteRecipe')}</div>
          <div style={styles.modalButtons}>
            <ButtonFull text={t('btnNo')} onButtonClick={() => setShowModal(false)} />
            <ButtonEmpty text={t('btnYes')} onButtonClick={() => onConfirmDelete()} />
          </div>
        </div>
      </div> : null}
      <div style={commonStyles.title}>{title}</div>
      <div style={commonStyles.body}>
        <div style={styles.searchContainer}>
          <div style={styles.searchBar}>
            <InputBase
              sx={{ pl: 1, flex: 1 }}
              placeholder={t('btnSearch')}
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              onKeyPress={(event) => onEnterPressed(event.key)}
            />
            <IconButton type="button" sx={{ p: 0 }} onClick={() => showRecipes()}>
              <SearchIcon style={{ color: colors.main }} />
            </IconButton>
          </div>
          <div style={{ marginRight: 16 }} />
          <ButtonFull text={t('btnAdd')} onButtonClick={() => navigate("newRecipe", { state: { categories, menus: menus.filter(item => item !== 'null') } })} />
        </div>
        <div style={{ display: 'flex', margin: '20px 26px 40px 26px' }}>
          <div style={styles.toolBar}>
            <RadioOption text={t("lblPhotos")} onButtonClick={(selected) => onTogglePhotos(selected)} isSelected={showPhotos} />
            <RadioOption text={t("lblMenu")} onButtonClick={(selected) => onToggleMenu(selected)} isSelected={showMenus} />
            <ButtonOption text={t("lblExport")} onButtonClick={() => onExport()} />
          </div>
        </div>
        {!isReady ?
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ReactLoading type="spin" color={colors.main} height={40} width={40} />
          </div> : renderItemList(visibleRecipes)
        }
      </div>
    </div>
  );
};

const styles = {
  searchContainer: {
    margin: '20px 20px 10px 20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch'
  },
  searchBar: {
    display: 'flex',
    justifyContent: 'stretch',
    padding: '0 12px',
    flex: 1,
    border: '1px solid lightgrey',
    borderRadius: 12,
    backgroundColor: colors.lightGrey
  },
  toolBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },

  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 10px'
  },
  headerCell: {
    padding: '0 20px',
    fontWeight: 400,
    textAlign: 'left',
    color: colors.text
  },
  cell: {
    padding: '10px 20px',
    fontSize: 16,
    fontWeight: '500',
  },
  firstCell: {
    width: 6,
  },
  gap: {
    width: 6,
    backgroundColor: colors.background,
  },
  lastCell: {
    width: 80,
    textAlign: 'center',
    backgroundColor: colors.background
  },
  modalWindow: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100
  },
  modalDiv: {
    width: 200,
    padding: '30px 40px',
    borderRadius: 12,
    borderStyle: 'solid',
    borderColor: colors.main,
    borderWidth: 2,
    backgroundColor: colors.background,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  modalText: {
    marginBottom: 20,
    textAlign: 'center'
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'space-around'
  },

  modalDivCategories: {
    position: 'absolute',
    padding: '15px 20px',
    borderRadius: 12,
    borderStyle: 'solid',
    borderColor: colors.grey,
    borderWidth: 1,
    backgroundColor: colors.background,
    display: 'flex',
    flexDirection: 'column',
  },
  modalCheckbox: {
    display: 'flex',
    alignItems: 'center'
  },
  modalCheckboxText: {
    fontSize: 14,

  },
};

export default RecipesScreen;
