export const colors = {
  darkGreen: "#044B26",
  green: "#088343",
  darkGray: "#707070",
  grey: "#B1ADAD",
  semiLightGrey: "#DBDBDB",
  lightGrey: "#F0F0F0",
  veryLightGrey: "#F8F8F8",
  darkRed: "#850303",
  red: "#BA0000",
  darkAmber: "#AB6202",
  amber: "#FF9100",
  white: "#FFFFFF",
  black: "#000000",
  button: "#044B26",
  darkBlue: "#27496d",
  transparant: "#0000",
  //
  main: "#044B26",
  background: "#FFFFFF",
  backgroundAlt: "#DDDDDD",
  text: "#707070",
  textLight: "#B1ADAD",
  textTitle: "#27496d",
  textNegative: "#FFFFFF",
  textError: "#BA0000",
  good: "#044B26",
  goodDark: "#044B26",
  warning: "#FF9100",
  warningDark: "#AB6202",
  bad: "#BA0000",
  badDark: "#850303",
  carb: "#27496d",
  protein: "#0c7b93",
  fat: "#00a8cc",
  delete: "#BA0000",
  percentageBar: "#00a8cc",
  link: "#379bdd",
};

export const massUnits = {
  kg: 1,
  gr: 0.001,
  lb: 0.45359,
  oz: 0.02834,
  pinch: 0.001
};

export const volumeUnits = {
  cup: 0.236588,
  tbsp: 0.01478,
  tsp: 0.00492
};

export const liquidUnits = {
  l: 1,
  ml: 0.001,
  fl_oz: 0.02957,
  pint: 0.48,
  cl: 0.01
};

export const volumesUnits = {
  l: 1,
  ml: 0.001,
  fl_oz: 0.02957,
  pint: 0.48,
  cl: 0.01,
  cup: 0.236588,
  tbsp: 0.01478,
  tsp: 0.00492
};

export const allUnits = ['kg', 'gr', 'lb', 'oz', 'pinch', 'cup', 'tbsp', 'tsp', 'l', 'ml', 'fl_oz', 'pint', 'unit'];

export const uselessWords = ["the", "of", "a", "an", "and", "le", "la", "de", "du"];
export const fractions = [
  { value: 0.5, fraction: "½" },
  { value: 0.25, fraction: "¼" },
  { value: 0.75, fraction: "¾" },
  { value: 0.3333, fraction: "⅓" },
  { value: 0.6667, fraction: "⅔" },
];

export const emptyUnit = { id: -1, code: "...", name: "...", others: [] };
export const completeUnits = [
  { id: 0, category: "volume", code: "unitTbsp", name: "tbsp", others: ["tbsp", "tablespoon", "tablespoons", "cas", "c.asoupe", "c.a.soupe", "c.as.", "casoupe", "cuillereasoupe", "cuilleresasoupe", "cuillereatable", "c.table", "cuilleresatable", "c.atable", "c.soupe"] },
  { id: 1, category: "volume", code: "unitTsp", name: "tsp", others: ["tsp", "teaspoon", "teaspoons", "cat", "c.athe", "c.a.the", "cuillereathe", "cuilleresathe", "c.ac.", "cathe", "c.the"] },
  { id: 2, category: "volume", code: "unitCup", name: "cup", others: ["cup", "cups", "c", "t", "tasse", "tasses"] },
  { id: 3, category: "mass", code: "unitPinch", name: "pinch", others: ["pinch", "pincee", "pincees"] },
  { id: 4, category: "volume", code: "unitPint", name: "pint", others: ["pint", "pints", "pinte", "pintes"] },
  { id: 5, category: "single", code: "unitUnit", name: "unit", others: ["unit", "units", "unites", "unite", "unitee", "un.", "un"] },
  { id: 6, category: "mass", code: "unitKg", name: "kg", others: ["kg", "kilogramme", "kilo", "kilos"] },
  { id: 7, category: "mass", code: "unitGr", name: "gr", others: ["gr", "g", "gramme", "grammes"] },
  { id: 8, category: "mass", code: "unitLb", name: "lb", others: ["lb", "lb", "lbs", "livre", "livres", "pound", "pounds"] },
  { id: 9, category: "mass", code: "unitOz", name: "oz", others: ["oz"] },
  { id: 10, category: "volume", code: "unitL", name: "l", others: ["l", "litre", "litres"] },
  { id: 11, category: "volume", code: "unitMl", name: "ml", others: ["ml"] },
  { id: 12, category: "volume", code: "unitFl_oz", name: "fl_oz", others: ["fl_oz", "floz", "oz liq"] },
  { id: 13, category: "volume", code: "unitCl", name: "cl", others: ["cl", "centilitre", "centilitres"] },
  { id: 14, category: "portions", code: "unitPortions", name: "portions", others: ["portion", "portions"] }
];
export const completeUnitsWithPortion = [
  { id: 0, category: "volume", code: "unitTbsp", name: "tbsp", others: ["tbsp", "tablespoon", "tablespoons", "cas", "c.asoupe", "c.a.soupe", "c.as.", "casoupe", "cuillereasoupe", "cuilleresasoupe", "cuillereatable", "c.table", "cuilleresatable", "c.atable", "c.soupe"] },
  { id: 1, category: "volume", code: "unitTsp", name: "tsp", others: ["tsp", "teaspoon", "teaspoons", "cat", "c.athe", "c.a.the", "cuillereathe", "cuilleresathe", "c.ac.", "cathe", "c.the"] },
  { id: 2, category: "volume", code: "unitCup", name: "cup", others: ["cup", "cups", "c", "t", "tasse", "tasses"] },
  { id: 3, category: "mass", code: "unitPinch", name: "pinch", others: ["pinch", "pincee", "pincees"] },
  { id: 4, category: "volume", code: "unitPint", name: "pint", others: ["pint", "pints", "pinte", "pintes"] },
  { id: 5, category: "single", code: "unitUnit", name: "unit", others: ["unit", "units", "unites", "unite", "unitee", "un.", "un"] },
  { id: 6, category: "mass", code: "unitKg", name: "kg", others: ["kg", "kilogramme", "kilo", "kilos"] },
  { id: 7, category: "mass", code: "unitGr", name: "gr", others: ["gr", "g", "gramme", "grammes"] },
  { id: 8, category: "mass", code: "unitLb", name: "lb", others: ["lb", "lb", "lbs", "livre", "livres", "pound", "pounds"] },
  { id: 9, category: "mass", code: "unitOz", name: "oz", others: ["oz"] },
  { id: 10, category: "volume", code: "unitL", name: "l", others: ["l", "litre", "litres"] },
  { id: 11, category: "volume", code: "unitMl", name: "ml", others: ["ml"] },
  { id: 12, category: "volume", code: "unitFl_oz", name: "fl_oz", others: ["fl_oz", "floz", "oz liq"] },
  { id: 13, category: "volume", code: "unitCl", name: "cl", others: ["cl", "centilitre", "centilitres"] },
  { id: 14, category: "portions", code: "unitPortions", name: "portions", others: ["portion", "portions"] }
];
export const completeUnits2 = {
  tbsp: { id: 0, category: "volume", code: "unitTbsp", name: "tbsp", others: ["tbsp", "tablespoon", "tablespoons", "cas", "c.asoupe", "c.a.soupe", "c.as.", "casoupe", "cuillereasoupe", "cuilleresasoupe", "cuillereatable", "c.table", "cuilleresatable", "c.atable", "c.soupe"] },
  tsp: { id: 1, category: "volume", code: "unitTsp", name: "tsp", others: ["tsp", "teaspoon", "teaspoons", "cat", "c.athe", "c.a.the", "cuillereathe", "cuilleresathe", "c.ac.", "cathe", "c.the"] },
  cup: { id: 2, category: "volume", code: "unitCup", name: "cup", others: ["cup", "cups", "c", "t", "tasse", "tasses"] },
  pinch: { id: 3, category: "mass", code: "unitPinch", name: "pinch", others: ["pinch", "pincee", "pincees"] },
  pint: { id: 4, category: "volume", code: "unitPint", name: "pint", others: ["pint", "pints", "pinte", "pintes"] },
  unit: { id: 5, category: "single", code: "unitUnit", name: "unit", others: ["unit", "units", "unites", "unite", "unitee", "un.", "un"] },
  kg: { id: 6, category: "mass", code: "unitKg", name: "kg", others: ["kg", "kilogramme", "kilo", "kilos"] },
  gr: { id: 7, category: "mass", code: "unitGr", name: "gr", others: ["gr", "g", "gramme", "grammes"] },
  lb: { id: 8, category: "mass", code: "unitLb", name: "lb", others: ["lb", "lb", "lbs", "livre", "livres", "pound", "pounds"] },
  oz: { id: 9, category: "mass", code: "unitOz", name: "oz", others: ["oz"] },
  l: { id: 10, category: "volume", code: "unitL", name: "l", others: ["l", "litre", "litres"] },
  ml: { id: 11, category: "volume", code: "unitMl", name: "ml", others: ["ml"] },
  fl_oz: { id: 12, category: "volume", code: "unitFl_oz", name: "fl_oz", others: ["fl_oz", "floz", "oz liq"] },
  cl: { id: 13, category: "volume", code: "unitCl", name: "cl", others: ["cl", "centilitre", "centilitres"] },
  portions: { id: 14, category: "portions", code: "unitPortions", name: "portions", others: ["portion", "portions"] }
};

export const links = [
  {
    language: "English",
    data: [
      { id: 0, name: "Hot for food", url: "https://www.hotforfoodblog.com/" },
      { id: 1, name: "Minimalist Baker", url: "https://minimalistbaker.com/" },
      { id: 2, name: "Rainbow Plant Life", url: "https://rainbowplantlife.com/" },
      { id: 3, name: "It doesn't taste like chicken", url: "https://itdoesnttastelikechicken.com/" },
      { id: 4, name: "Serious Eats", url: "https://www.seriouseats.com/" },
      //{ id: 5, name: "Allrecipes", url: "https://www.allrecipes.com/" },
      { id: 6, name: "Food network", url: "https://www.foodnetwork.com/" },
      { id: 7, name: "Tasty", url: "https://tasty.co/" },
      //{ id: 8, name: "The Paleo Mom", url: "https://www.thepaleomom.com//" },
    ]
  },
  {
    language: "French",
    data: [
      { id: 0, name: "Loounie Cuisine", url: "https://www.loouniecuisine.com/" },
      { id: 1, name: "Ricardo", url: "https://www.ricardocuisine.com/" },
      { id: 2, name: "Marmiton", url: "https://www.marmiton.org/" },
      { id: 3, name: "Cuisine Actuelle", url: "https://www.cuisineactuelle.fr/" },
      { id: 4, name: "750g", url: "https://www.750g.com/" },
      { id: 5, name: "Cuisineaz", url: "https://www.cuisineaz.com/" },
      { id: 6, name: "Mordu", url: "https://ici.radio-canada.ca/mordu/recettes/6619/courgettes-farcies-italienne" }
    ]
  }
];

export const shoppingLinks = [
  { id: 0, name: "Fermier de famille", url: "https://www.fermierdefamille.com/fr/" },
  { id: 1, name: "Bio Terre Épicerie Santé", url: "https://bio-terre.com/" },
  { id: 2, name: "BocoBoco.ca - épicerie zéro déchet et gourmande", url: "https://www.bocoboco.ca" },
  { id: 3, name: "Aliment Merci", url: "https://www.alimentsmerci.com/" },
  { id: 4, name: "Marché Jean-Talon", url: "https://www.marchespublics-mtl.com/en/marches/jean-talon-market/" },
  { id: 5, name: "Marché Atwater", url: "https://www.marchespublics-mtl.com/en/marches/atwater-marker/" },
  { id: 6, name: "Épicerie LOCO", url: "https://www.epicerieloco.ca/en/" },
  { id: 7, name: "La réserve naturelle", url: "https://lareservenaturelle.com/fr/" },
  { id: 8, name: "Mission Santé Thuy", url: "https://www.facebook.com/missionsantethuy/" },
  { id: 9, name: "Marché Oda", url: "https://www.marcheoda.com/" },
  { id: 10, name: "Marché SecondeVie", url: "https://second-life.ca/" },
];

export const takeoutLinks = [
  { id: 0, name: "Panthère Verte", url: "https://www.lapanthereverte.com/" },
  { id: 1, name: "Lola Rosa", url: "https://www.lolarosa.ca/" },
  { id: 2, name: "Véganation", url: "https://www.veganationmtl.com/" },
  { id: 3, name: "Sham", url: "https://www.shammontreal.com/" },
  { id: 4, name: "Sushi Momo", url: "https://www.sushimomo.ca/" },
  { id: 5, name: "Umami Ramen et Izakaya", url: "https://www.umamiramenizakaya.ca/" },
  { id: 6, name: "Aux Vivres", url: "https://www.auxvivres.com/" },
  { id: 7, name: "Pâtisserie Zébulon", url: "https://www.patisseriezebulon.com/" },
  { id: 8, name: "Vegâteau", url: "https://www.vegateau.com/" },
  { id: 9, name: "Lu & I", url: "https://www.patisserieluandi.com/" },
  { id: 10, name: "Sophie Sucrée", url: "https://www.sophiesucree.com/" },
];

export const optionsData = [
  { title: "PreferencesOptionsTitle", nav: "preferences", iconName: "cog" },
  { title: "HealthOptionsTitle", nav: "health", iconName: "heart-plus" },
  { title: "InfosOptionsTitle", nav: "infos", iconName: "info-circle" },
];

export const answerValues = [
  { value: "sliderNoAnswerTitle", desc: "sliderNoAnswerDesc", code: null },
  { value: "sliderNeverTitle", desc: "sliderNeverDesc", code: "never" },
  { value: "sliderRarelyTitle", desc: "sliderRarelyDesc", code: "rarely" },
  { value: "sliderOccasionallyTitle", desc: "sliderOccasionallyDesc", code: "occasionally" },
  { value: "sliderOftenTitle", desc: "sliderOftenDesc", code: "often" }
];

export const skip = {
  en: ["finely", "chopped", "halved", "whole", "small", "big", "average", "medium", "large", "package",
    "neutral", "more", "taste", "plus", "flat", "sliced", "thin", "diagonally", "fry", "thawed", "sliced",
    "thinly", "spread", "favorite", "shredded", "ribbons", "minced", "shaved", "drained", "pressed", "cut",
    "trimmed", "crushed", "healthy", "each", "diced", "uncooked", "toasted", "stir-fry", "grated", "roughly",
    "steamed", "ground", "ripe", "hot", "raw", "canned", "vegan", "cube", "sweet", "can", "fresh", "dark", "sea", "coarse"],
  fr: ["frais", "gros", "grosse", "grosses", "moyen", "petit", "petits", "petite", "petites", "broyé",
    "broyés", "broyée", "broyées", "moulu", "moulue", "moulus", "grillé", "grillés", "grillée", "grillées",
    "émincé", "émincés", "émincée", "émincées", "finement", "tranché", "tranchés", "tranchée", "tranchées",
    "épépiné", "épépinés", "épépinée", "épépinées", "décongelé", "décongelés", "décongelée", "décongelées",
    "réduite", "réduites", "sèche", "sèches", "haché", "hachés", "hachée", "hachées", "coupé", "coupés",
    "coupée", "coupées", "rincé", "rincés", "rincée", "rincées", "égoutté", "égouttés", "égouttée", "égouttées",
    "ciselé", "ciselés", "ciselée", "ciselées", "assaisonnement", "dés", "râpé", "râpés", "râpée", "râpées",
    "écrasé", "écrasés", "écrasée", "écrasées", "tendre", "tendres", "goût", "goûts"]
};

export const colorValues = {
  ingredientLow: 1.54,
  ingredientHigh: 2.3
};

export const colorValuesRecipes = {
  ingredientLow: 0.8,
  ingredientHigh: 1.2
};

export const months = {
  en: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  fr: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]
};

export const statsLinks = [
  'https://medias.sncf.com/sncfcom/rse/General-method_infoGHG.pdf',
  'https://fr.wikipedia.org/wiki/Terre',
  'https://www.usda.gov/media/blog/2015/03/17/power-one-tree-very-air-we-breathe',
  'https://ourworldindata.org/travel-carbon-footprint',
];

//export const urlHost = "https://ern-staging-public.s3.amazonaws.com/";
//export const urlHostRegex = /^https:\/\/ern-staging-public\.s3\.amazonaws\.com\//;
export const urlHost = "https://ern-dev-public.s3.amazonaws.com/";
export const urlHostRegex = /^https:\/\/ern-dev-public\.s3\.amazonaws\.com\//;
